import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
const routes = [{
  //path: '/',
  //name: 'home',
  //component: HomeView
  path: '/',
  name: 'index',
  component: () => import('../views/mainSite/index.vue')
}, {
  path: '/setting',
  name: 'index-setting',
  component: () => import('../views/mainSite/indexSetting.vue')
}, {
  path: '/review',
  name: 'index-review',
  component: () => import('../views/mainSite/indexReview.vue')
}, {
  path: '/report',
  name: 'index-report',
  component: () => import('../views/mainSite/indexReport.vue')
}, {
  path: '/user',
  name: 'userman-index',
  component: () => import('../views/mainSite/usermanager/user-index.vue')
}, {
  path: '/user/detail/:id',
  name: 'userman-detail',
  component: () => import('../views/mainSite/usermanager/user-detail.vue')
}, {
  path: '/user/create',
  name: 'userman-create',
  component: () => import('../views/mainSite/usermanager/user-create.vue')
}, {
  path: '/chinhanh',
  name: 'cskcb-index',
  component: () => import('../views/mainSite/cskcbManager/cskcb-index.vue')
}, {
  path: '/chinhanh/detail/:id',
  name: 'cskcb-detail',
  component: () => import('../views/mainSite/cskcbManager/cskcb-detail.vue')
}, {
  path: '/chinhanh/create',
  name: 'cskcb-create',
  component: () => import('../views/mainSite/cskcbManager/cskcb-create.vue')
}, {
  path: '/login',
  name: 'login-index',
  component: () => import('../views/auth/login.vue')
}, {
  path: '/register',
  name: 'register-index',
  component: () => import('../views/auth/register.vue')
}, {
  path: '/reviewsman',
  name: 'reviewsman-index',
  component: () => import('../views/mainSite/CustomerReviews/review-index.vue')
}, {
  path: '/reviewsman/create',
  name: 'create-reviewsman-index',
  component: () => import('../views/mainSite/CustomerReviews/review-create.vue')
}, {
  path: '/reviewsman/detail/:id',
  name: 'detail-reviewsman-index',
  component: () => import('../views/mainSite/CustomerReviews/review-detail.vue')
}, {
  path: '/reviewsquestion/detail/:id',
  name: 'detail-reviewsman-question',
  component: () => import('../views/mainSite/CustomerReviews/review-detail-question.vue')
}, {
  path: '/playscore/:id',
  name: 'playscore-index',
  component: () => import('../views/mainSite/playScore/playscore-index.vue')
}, {
  path: '/playstart/:id',
  name: 'playscore-start',
  component: () => import('../views/mainSite/playScore/playscore-start.vue')
}, {
  path: '/customer',
  name: 'customer-index',
  component: () => import('../views/mainSite/customerManager/customer-index.vue')
}, {
  path: '/customer/detail/:id:mapk',
  name: 'customer-detail',
  component: () => import('../views/mainSite/customerManager/customer-detail.vue')
}, {
  path: '/customer/create/:mapk',
  name: 'customer-create',
  component: () => import('../views/mainSite/customerManager/customer-create.vue')
}, {
  path: '/report/review',
  name: 'report-review-index',
  component: () => import('../views/mainSite/report/report-review-index.vue')
}, {
  path: '/report/detail/:id:title',
  name: 'report-review-detail',
  component: () => import('../views/mainSite/report/report-review-detail.vue')
}, {
  path: '/product',
  name: 'product.list',
  component: () => import('../views/products/List.vue')
}, {
  path: '/product/create',
  name: 'product.create',
  component: () => import('../views/products/Form.vue')
}, {
  path: '/user',
  name: 'user.list',
  component: () => import('../views/user/List.vue')
}, {
  path: '/user/create',
  name: 'user.create',
  component: () => import('../views/user/Form.vue')
}, {
  path: '/tiepdon/list',
  name: 'tiepdon.list',
  component: () => import('../views/tiepdon/List.vue')
}, {
  path: '/tiepdon',
  name: 'tiepdon.index',
  component: () => import('../views/tiepdon/index.vue')
}, {
  path: '/taikham/:id/:title',
  name: 'taikham.index',
  component: () => import('../views/taikham/index.vue')
}, {
  path: '/chatbot/',
  name: 'index-chatbot',
  component: () => import('../views/mainSite/chatbot/chatbot-index.vue')
}, {
  path: '/category/:id',
  name: 'category-index',
  component: () => import('../views/mainSite/chatbot/categoryManager/category-index.vue')
}, {
  path: '/category/detail/:id',
  name: 'category-detail',
  component: () => import('../views/mainSite/chatbot/categoryManager/category-detail.vue')
}, {
  path: '/category/create/:area_id',
  name: 'category-create',
  component: () => import('../views/mainSite/chatbot/categoryManager/category-create.vue')
}, {
  path: '/area',
  name: 'area-index',
  component: () => import('../views/mainSite/chatbot/areaManager/area-index.vue')
}, {
  path: '/area/detail/:id',
  name: 'area-detail',
  component: () => import('../views/mainSite/chatbot/areaManager/area-detail.vue')
}, {
  path: '/area/create',
  name: 'area-create',
  component: () => import('../views/mainSite/chatbot/areaManager/area-create.vue')

}, {
  path: '/responsedata/:id',
  name: 'responsedata-index',
  component: () => import('../views/mainSite/chatbot/reasponsedataManager/reasponsedata-index.vue')
}, {
  path: '/responsedata/detail/:id',
  name: 'responsedata-detail',
  component: () => import('../views/mainSite/chatbot/reasponsedataManager/reasponsedata-detail.vue')
}, {
  path: '/responsedata/create/:category_id',
  name: 'responsedata-create',
  component: () => import('../views/mainSite/chatbot/reasponsedataManager/reasponsedata-create.vue')
}
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
export default router
