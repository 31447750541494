<template>

    <head>
        <meta http-equiv="Content-Security-Policy" content="upgrade-insecure-requests" />
    </head>

    <body>
        <div class="bg-main">
            <div class="">
                <MainHeader />
            </div>
            <div class="container-fluid" style="height: 900px;">
                <router-view />
            </div>
        </div>
    </body>
</template>
<style>
</style>
<script>
    import { useMenu } from "./store/use-menu.js"
    import MainHeader from './views/header/index-header.vue';
    import headerNew from './views/components/header_new.vue';

    export default ({
        components: {
            MainHeader,
        },
        data() {
            return {
            }
        },
    });
</script>

<style>
    .bg-main {
        width: 100%;
        min-height: 100%;
        background: #f0f2f5 url("./assets/background.ebcb9160.svg") no-repeat 50%;
        background-size: 100%;
        position: relative;
    }
</style>