import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap/dist/css/bootstrap-utilities.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'ant-design-vue/dist/antd.css';
import { createPinia } from 'pinia';
import { VueQrcodeReader } from "vue-qrcode-reader";
//import { library } from '@fortawesome/fontawesome-svg-core'
//import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
//import { faTwitter } from '@fortawesome/free-brands-svg-icons'
//import { faUserSecret } from '@fortawesome/free-solid-svg-icons'
//library.add(faUserSecret, faTwitter);
import './static/fontawesome/css/all.min.css';
import {
    Button, Table, Card, DatePicker, Drawer, PageHeader,
    Menu, List, Alert, Input, InputPassword, Avatar,
    Checkbox, Switch, Pagination, Badge, Tag, notification,
    Slider, Grid, Row, Col, InputNumber, Radio, Modal, Result, Layout, Select, Popconfirm, Typography, Tabs

} from 'ant-design-vue';

const app = createApp(App)
//app.component('font-awesome-icon', FontAwesomeIcon);
app.use(router);
app.use(createPinia());
app.use(VueQrcodeReader);
axios.defaults.baseURL = 'https://review.ainghia.vn/apipg/';
app.use(VueAxios, { $request: axios })
app.mount('#app')
app.use(Tabs);
app.use(Typography);
app.use(Popconfirm);
app.use(Layout);
app.use(Select);
app.use(Modal);
app.use(Result);
app.use(InputNumber);
app.use(Radio);
app.use(Row);
app.use(Col);
app.use(Grid);
app.use(Slider);
app.use(notification);
app.use(Tag);
app.use(Badge);
app.use(Pagination);
app.use(Switch);
app.use(Checkbox);
app.use(PageHeader);
app.use(Drawer);
app.use(Avatar);
app.use(Input);
app.use(Button);
app.use(InputPassword);
app.use(Table);
app.use(List);
app.use(Menu);
app.use(Card);
app.use(Alert);
app.use(DatePicker);




