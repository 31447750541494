<template>
    <div v-if="store.isLogin" class="" style="border-top: 4px solid #ffa900;">
        <a-page-header style="background-color: rgb(10, 142, 144);" class="m-0 p-1 d-none" v-bind:sub-title="store.name"
            @back="() => $router.go(-1)">
            <template #extra>
                <a-button @click.prevent="gohomepage"><i class="fa-solid fa-house"></i>Quản lý</a-button>
                <a-button @click.prevent="gohomepage"><i class="fa-solid fa-house"></i>Bài đánh giá</a-button>
                <a-button @click.prevent="gohomepage"><i class="fa-solid fa-house"></i></a-button>
                <a-button @click.prevent="handleLogout"><i class="fa-solid fa-right-from-bracket"></i></a-button>
            </template>
        </a-page-header>
        <a-menu v-model:selectedKeys="current" mode="horizontal" theme="light">
            <a-menu-item key="home" @click.prevent="gohomepage"> Trang chủ </a-menu-item>
            <a-sub-menu v-if="store.getisAdmin()">
                <template #title>Chức năng</template>
                <a-menu-item-group title="Cài đặt">
                    <a-menu-item key="setting:0" @click.prevent="gosettingpage">Cài đặt chung</a-menu-item>
                    <a-menu-item key="setting:1" @click.prevent="gosettingpage1">Bài đánh giá</a-menu-item>
                    <a-menu-item key="setting:2" @click.prevent="gosettingpage2">Tài khoản</a-menu-item>
                    <a-menu-item key="setting:3" @click.prevent="gosettingpage3">Khách hàng</a-menu-item>
                    <a-menu-item key="setting:4" @click.prevent="gosettingpage4">Chi nhánh</a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group title="Thống kê">
                    <a-menu-item key="report:1" @click.prevent="goreportpage1">Thống kê lượt đánh giá</a-menu-item>
                </a-menu-item-group>
                <a-menu-item-group title="Đánh giá">
                    <a-menu-item key="review:1" @click.prevent="goreviewpage">Bắt đầu đánh giá</a-menu-item>
                </a-menu-item-group>


                <a-menu-item-group title="Chatbot">
                    <a-menu-item key="chatbot:1" @click.prevent="gochatbotArea">Category</a-menu-item>
                </a-menu-item-group>
            </a-sub-menu>
            <a-sub-menu>
                <template #title>Tài khoản</template>
                <a-menu-item-group>
                    <a-menu-item key="user:0" @click.prevent="handleChangePassword">Đổi mật khẩu</a-menu-item>
                    <a-menu-item key="user:1" @click.prevent="handleLogout">Đăng xuất</a-menu-item>
                </a-menu-item-group>
            </a-sub-menu>
        </a-menu>
    </div>
    <a-modal v-model:visible="modalOpen" v-model:title="titleModal" footer="">
        <a-card v-if="modalOpen">
            <div class="mt-4 d-flex justify-content-center">
                <h3>Đổi mật khẩu</h3>
            </div>
            <div class="row">
                <a-input-password v-model:value="password" type="" placeholder="mật khẩu ..." allow-clear />
            </div>
            <div class="mt-1 row"><a-input-password v-model:value="confirm_password" placeholder="nhập lại mật khẩu"
                    allow-clear />
            </div>
            <div class="mt-4 d-flex justify-content-center">
                <a-button type="danger" @click.prevent="handleAcceptChangepass"> Đồng ý</a-button>
            </div>

        </a-card>
    </a-modal>
</template>
<script>
    import { MailOutlined, AppstoreOutlined, SettingOutlined } from '@ant-design/icons-vue';
    import { useLogin } from "../../store/use-login.js";
    import { ref } from 'vue';
    import { InputPassword, message } from 'ant-design-vue';
    const current = ref(['home']);

    export default ({
        data() {
            const store = useLogin();
            return {
                store, current,
                modalOpen: false, titleModal: '', password: '', confirm_password: ''
            }
        },
        components: {
            MailOutlined,
            AppstoreOutlined,
            SettingOutlined,
        },
        created() {
        },
        methods: {
            gohomepage() {
                this.$router.push({ name: 'index' });
            },
            gosettingpage() {
                this.$router.push({ name: 'index-setting' });
            },
            gosettingpage1() {
                this.$router.push({ name: 'reviewsman-index' });
            },
            gosettingpage2() {
                this.$router.push({ name: 'userman-index' });
            },
            gosettingpage3() {
                this.$router.push({ name: 'customer-index' });
            },
            gosettingpage4() {
                this.$router.push({ name: 'cskcb-index' });
            },
            goreportpage1() {
                this.$router.push({ name: 'report-review-index' });
            },
            goreviewpage() {
                this.$router.push({ name: 'index-review' });
            },
            gochatbotArea() {
                this.$router.push({ name: 'area-index' });
            },
            handleChangePassword() {
                this.modalOpen = true;
                //message.info('Xin lỗi! Chức năng này đang tạm khóa');
            },
            async handleLogout() {
                this.$request.defaults.headers.common['token'] = localStorage.getItem('token');
                const respone = await this.$request.post('auth/logout');
                message.success('Đã đăng xuất thành công tài khoản');
                //console.log(respone);
                localStorage.clear();
                this.store.onLogin(false, '');
                this.$router.push({ name: 'login-index' });
            },
            async handleAcceptChangepass() {
                this.$request.defaults.headers.common['token'] = localStorage.getItem('token');
                const respone = await this.$request.post('user/updatepass/' + localStorage.getItem('user'), {
                    password: this.password,
                    confirm_password: this.confirm_password,
                });
                if (!respone.data.access) {
                    this.store.onLogin(false, '');
                    message.info(respone.data.message);
                    return;
                } if (respone.data.success) {
                    message.info(respone.data.message);
                    this.password = '';
                    this.confirm_password = '';
                    this.modalOpen = false;
                    return;
                }
                this.log = respone.data.message;
                message.info(respone.data.message);
            },
        }
    });
</script>

<style>
    .demo-page-header :deep(tr:last-child td) {
        padding-bottom: 0;
    }
</style>